// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import axios from "axios";

const config = useJwt.jwtConfig;

// ** Handle User Login
export const handleLogin = (data) => {
  localStorage.setItem("userToken", JSON.stringify(data));

  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      data,
      config,
    });
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    const scheduledEvent = JSON.parse(localStorage.getItem("scheduledEvent"));
    clearTimeout(scheduledEvent?.timeout);
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });

    // ** Remove user, accessToken & refreshToken from localStorage
    // localStorage.removeItem("userData");
  };
};
